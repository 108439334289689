import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SEO from '../../components/mini/seo';

import Layout from '../../components/mini/layout';
import FlexCard from '../../components/mini/flexcard';
import DrivingSchoolCard from '../../components/drivingschoolcard';
import Tracks from '../../components/mini/multipletrack';
import settings from '../../../settings';

const arrow = `${settings.IMAGES_BASE_URL}/images/assets/scroll-arrow-KO.svg`;
const minimastercard = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-mastercard.jpg`;
const ministuntcar = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-stunt-driving-school-box.jpg`;
const miniconeboxescar = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-autocross-cones-box.jpg`;
const minidriftingcar = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-stunt-drifting-class-box.jpg`;
const herobackground = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-main-banner.jpg`;
const miniscclassbox = `${settings.IMAGES_BASE_URL}/images/mini/home/mini-sc-class-box.jpg`;
const manualClass = `${settings.IMAGES_BASE_URL}/images/mini/classes/mini-manual-class.jpg`;
class IndexPage extends React.Component {


  scrollToRef = () => {
    window.scrollTo({
      top: 1000,
      left: 0,
      behavior: 'smooth'
    });
  };

  getCards = () => {
    return (
      <>
        <FlexCard
          rowType="row"
          image={manualClass}
          imageAlt="SAVE THE MANUALS"
          header="SAVE THE MANUALS"
          subheader="LEARN TO DRIVE A MANUAL TRANSMISSION"
          text="Learn how to drive a manual MINI on our closed course. Don’t worry, no one will honk if you stall."
          btnText="Learn More"
          btnStyle="btn-black"
          route="/mini/schools"
        />
        <FlexCard
          rowType="row-reverse"
          image={ministuntcar}
          imageAlt="Mini Stunt Car"
          header="pull a stunt"
          subheader="mini stunt driving school."
          text="Ever wanted to become a stunt-car driver? Here’s your chance. Hit the parking brake for J-turns and more as you slide around the track."
          btnText="Learn More"
          btnStyle="btn-black"
          route="/mini/schools"
        />
        <FlexCard
          rowType="row"
          image={minimastercard}
          imageAlt="Mini magic"
          header="Fuel The Fun"
          subheader="Earn Rewards on everyday purchases"
          text="Designed for drivers, the MINI Card earns points toward rewards and includes exceptional complimentary benefits. With up to 4X points per $1 spent on eligible MINI purchases, earning has never been so easy. It's time to explore your benefits - this is your road to valuable rewards."
          btnText="Learn More"
          btnStyle="btn-black"
          externalURL="http://www.myminicreditcard.com/37989"
        />
      </>
    )
  }

  render() {
    return (
      <Layout>
        <SEO title="MINI Driving Experience USA | MINI Driving School" description="Over 50 years of motorsport legacy goes into the MINI Driving Experience in the form of Group Events, Stunt Driving School and autocross and track school." />

        <div className="mini__hero">
          <LazyLoadImage effect="blur" src={herobackground} alt="MINI" />
          <button className="arrow" onClick={this.scrollToRef} onKeyDown={this.scrollToRef} title="arrow">
            <LazyLoadImage effect="blur" src={arrow} alt="arrow" className="scroll-arrow" />
          </button>
        </div>
        <section className="performance-center">
          <div className="container">
            <div className="drivingschool__header">
              <h1 className="linethrough-header">THE MINI DRIVING EXPERIENCE</h1>
              <p>
                Over 50 years of motorsport legacy live in each John Cooper Works MINI, something you can<br/> experience for yourself at the MINI Driving Experience.
              </p>
            </div>
          </div>
          {this.getCards()}
        </section>

        <section className="drivingschool">
          <div className="container">
            <div className="drivingschool__header">
              <h2 className="linethrough-header">Mini fun, now in two locations</h2>
            </div>
          </div>
        </section>
        <Tracks />
        <section className="drivingschool">
          <div className="container">
            <div className="drivingschool__header">
              <h2 className="linethrough-header">mini driving experience schools</h2>
            </div>
          </div>
          <DrivingSchoolCard
            rowType="row"
            image={miniconeboxescar}
            imageAlt="Mini Stunt Drifting Class Box"
            header="MINI AUTOCROSS & TRACK SCHOOL"
            text="Learn the basics of fun behind the wheel of a John Cooper Works MINI on our closed course with professional driving instructors."
            subheaderMini="THE BASICS are anything but."
            learnUrl="/mini/schools"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-red"
            bookUrl="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=MINI&class=MINI_Autocross_%26_Track_School"
          />
          <DrivingSchoolCard
            rowType="row"
            image={minidriftingcar}
            imageAlt="Mini Stunt Driving School Box"
            header="MINI stunt driving school"
            subheaderMini="pull some stunts, be a hero."
            text="Live the dream of sliding into the perfect parking spot and hitting the e-brake for some J-turn action. Looks cool, feels even better."
            learnUrl="/mini/schools"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-red"
            bookUrl="https://pds.eventsbmw.com/?location=Thermal%2C%20CA&brand=MINI&class=MINI_Stunt_Driving_School"
          />
          <DrivingSchoolCard
            rowType="row"
            image={miniscclassbox}
            imageAlt="MINI driving experience"
            header="MINI driving experience"
            subheaderMini="LIKE DRIVING A MINI RACE CAR"
            text="Now available on the East Coast, the MINI Driving Experience packs tons of adrenaline in just 4 hours. Buckle up."
            learnUrl="/mini/schools"
            learnBtnStyle="btn-black"
            bookBtnStyle="btn-red"
            bookUrl="https://pds.eventsbmw.com/?location=Spartanburg,%20SC&brand=MINI&class=MINI_Driving_Experience"
          />
        </section>
      </Layout>
    );
  }
}

export default IndexPage;